import React from "react";
import BankAccount from "./bank-account";

const Body = props => {
	const { caveatItems } = props;
	return (
		<>
			<BankAccount {...props} />
			<br />
			<p>
				Deposits are refundable if the sale is canceled, redeemed prior to
				closing or if you are outbid at auction. While most title-clearing and
				closing costs are fixed, the number of certified notices and encumbrance
				cancellations required is determined during the research period, which
				may result in an increase or decrease in the starting price. The
				starting price is updated prior to the start of bidding or sale of the
				property.
			</p>
			<br />
			<div>
				<h4>I understand the following:</h4>
				<ul>
					{caveatItems.map(c => (
						<li key={c.caption}>
							<div className="checkbox">
								<label>
									<input onChange={c.onChange} type="checkbox" />
									<strong>{c.caption}</strong>
								</label>
							</div>
						</li>
					))}
				</ul>
			</div>
		</>
	);
};

export default Body;
