import React, { useState, useCallback } from "react";
import useSheet from "react-jss";
import { get } from "lodash";
import moment from "moment";
import AuctionGoing from "../going";
import { robotoStack } from "../../typography";
import Timeline from "./timeline";
import AuctionInfo from "./info";
import PropertyDetails from "./property-details";
import Costs from "./costs";
import InterestedParties from "./interested-parties";
import LineItems from "./line-items";
import ImageSection from "./image-section";
import DocumentSection from "./document-section";
import AuctionAlerts from "./alerts";
import W9 from "./w9";
import connect from "./connect";
import { Link } from "@reach/router";
import { Grid } from "react-bootstrap";

const useStateDepositModel = props => {
	const [depositDate, setDepositDate] = useState();

	const onDepositPlaced = useCallback(() => {
		setDepositDate(moment().format("MM/DD/YYYY"));
	}, []);

	if (!depositDate) {
		return { ...props, onDepositPlaced };
	}

	const newProps = { ...props };
	newProps.hasDeposit = true;
	newProps.status.isPreResearch = false;
	newProps.status.isPreSale = true;
	newProps.user.isDepositer = true;
	newProps.timeline.depositedOn = depositDate;
	newProps.onDepositPlaced = onDepositPlaced;
	return newProps;
};

const Root = props => {
	props = useStateDepositModel(props);

	const {
		isOffline,
		lotNumber,
		isFetched: isLoaded,
		taxAuthority,
		isTaxSale,
		status,
		user,
		auction,
		error
	} = props;

	const notFound = get(error, "response.status") === 404;

	if (notFound) {
		return (
			<DetailsChrome>
				<div
					style={{
						minHeight: "30vh",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center"
					}}
				>
					<h1>Auction not found</h1>
					<p>
						No active or upcoming auction with the lot number {lotNumber} was
						found.
					</p>
					<Link to="/auctions">Search for more auctions</Link>
				</div>
			</DetailsChrome>
		);
	}

	const showReceivables =
		get(taxAuthority, "code") !== "CCM" && get(taxAuthority, "code") !== "HCT";
	const showW9 =
		user &&
		get(taxAuthority, "w9.requiresW9") &&
		isTaxSale &&
		status.isSold &&
		auction.winningBid &&
		auction.winningBid.isYourBid;

	return (
		<DetailsChrome>
			<>
				{isLoaded ? <AuctionAlerts {...props} /> : null}
				{!isOffline && props.auction ? (
					<div style={{ margin: "0 0 10px 0" }}>
						<AuctionGoing {...{ lotNumber }} auction={props.auction} jumbo />
					</div>
				) : null}
				<div itemScope itemType="http://schema.org/Offer">
					<div className="prop-details__section">
						<Timeline {...props} />
						<AuctionInfo {...props} />
						<PropertyDetails {...props} />
						{showReceivables ? <Costs {...props} /> : null}
						<InterestedParties
							accountNumber={props.accountNumber}
							taxAuthority={props.taxAuthority}
						/>
						{showW9 ? <W9 taxAuthority={taxAuthority} /> : null}
						<LineItems {...props} />
						<ImageSection {...props} />
						<DocumentSection {...props} />
					</div>
				</div>
			</>
		</DetailsChrome>
	);
};

const DetailsChrome = ({ children }) => (
	<div
		className="site-main-container"
		style={{
			padding: "10px 0px"
		}}
	>
		<Grid>{children}</Grid>
	</div>
);

export default useSheet({ container: robotoStack })(connect(Root));
